import React, { useState } from "react";

import { apiFetch } from "../../../../../../apiBindings";
import pushToAnalytics from "../../../../../../utils/analytics";
import StorageHandler from "../../../../../../utils/StorageHandler";
import Columns from "../../../../Columns";
import DatePicker from "../../../../forms/DatePicker";
import FormError from "../../../../forms/FormError";
import NewCheckbox from "../../../../forms/NewCheckbox";
import NewInput from "../../../../forms/NewInput";
import LoadingIndicator from "../../../../LoadingIndicator";
import Margin from "../../../../Margin";
import Button from "../../../../NewForms/Button";
import NewHeadline from "../../../../NewHeadline";
import { UncontrolledNotification as Notification } from "../../../../Notification";

const FluessiggasContact = ({ name = "fluessiggas-counter-level" }) => {
    const storage = new StorageHandler();

    /**
     * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
     * he can send again.
     *
     * @param {*} timestamp
     */
    const canSend = (timestamp) => {
        if (timestamp === null) {
            return true;
        }
        return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
    };

    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [nameForm, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [counterNumber, setCounterNumber] = useState("");
    const [counterLevel, setCounterLevel] = useState("");
    const [date, setDate] = useState("");
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] =
        useState(false);
    const [emailSent, setEmailSent] = useState(
        !canSend(
            storage.get("fluessiggas-counter-level:contact-form:sent-at", null)
        )
    );

    const onSubmit = (event) => {
        event.preventDefault();
        if (errors.length === 0) {
            // Check if the fields are valid
            if (
                nameForm === "" ||
                email === "" ||
                customerNumber === "" ||
                customerNumber === "" ||
                counterLevel === "" ||
                date === ""
            ) {
                const localErrors = [...errors];

                if (nameForm === "") {
                    localErrors.push("nameForm");
                }
                if (email === "") {
                    localErrors.push("email");
                }
                if (customerNumber === "") {
                    localErrors.push("customerNumber");
                }
                if (counterNumber === "") {
                    localErrors.push("counterNumber");
                }
                if (counterLevel === "") {
                    localErrors.push("counterLevel");
                }
                if (date === "") {
                    localErrors.push("date");
                }
                if (isPrivacyPolicyAccepted === false) {
                    localErrors.push("isPrivacyPolicyAccepted");
                }
                setErrors(localErrors);
                return;
            }
            setSending(true);
            void apiFetch("/contact/fluessiggas-counter-level", {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({
                    name: nameForm,
                    phone,
                    address,
                    email,
                    customerNumber,
                    counterNumber,
                    counterLevel,
                    date,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        storage.set(`${name}:contact-form:sent-at`, Date.now());
                        storage.set("app:uid", data.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: `${name}:contact-form`,
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    const onChangeName = (event) => {
        setName(event.target.value);
    };

    const onChangePhone = (event) => {
        setPhone(event.target.value);
    };

    const onChangeAddress = (event) => {
        setAddress(event.target.value);
    };

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const onChangeCounterLevel = (event) => {
        setCounterLevel(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onDateChanged = (date) => {
        setDate(date);
    };

    const onChangeIsPrivacyAccepted = ({ target }) => {
        setIsPrivacyPolicyAccepted(target.value);
    };

    const onChangeCounterNumber = (event) => {
        setCounterNumber(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const onChangeCustomerNumber = (event) => {
        setCustomerNumber(event.target.value);
        if (event.target.value !== "") {
            clearError("message");
        }
    };

    const addFormError = (key) => {
        if (!errors.includes(key)) {
            setErrors([...errors, key]);
        }
    };

    const hasErrorAt = (key) => {
        return errors.filter((item) => item === key).length > 0;
    };

    /**
     * Remove an element from the errors.
     *
     * @param {*} key
     */
    const clearError = (key) => {
        setErrors(errors.filter((item) => item !== key));
    };

    return (
        <>
            <Margin top="30">
                {sendingError ? (
                    <Notification>
                        <NewHeadline>Entschulding</NewHeadline>
                        <p>
                            Es ist leider ein Fehler aufgetreten. Versuche es
                            bitte in Kürze erneut.
                        </p>
                        <Button
                            onClick={() => setSendingError(false)}
                            fullWidth
                        >
                            Zum Formular
                        </Button>
                    </Notification>
                ) : sending ? (
                    <Notification>
                        <LoadingIndicator>
                            Deine Nachricht wird übermittelt. Habe einen Moment
                            Geduld.
                        </LoadingIndicator>
                    </Notification>
                ) : emailSent ? (
                    <Notification>
                        <NewHeadline>Vielen Dank für Deine Anfrage</NewHeadline>
                        <p>
                            Wir haben Deine Anfrage erhalten und melden uns in
                            Kürze bei Dir.
                        </p>
                    </Notification>
                ) : (
                    <>
                        <form onSubmit={onSubmit}>
                            <NewHeadline>Zählerstand eingeben</NewHeadline>
                            <Columns>
                                <Columns.Column>
                                    <NewInput
                                        value={nameForm}
                                        onChange={onChangeName}
                                        placeholder="Name"
                                        validation="name"
                                        onError={() => addFormError("nameForm")}
                                        onErrorSolved={() =>
                                            clearError("nameForm")
                                        }
                                        forceError={hasErrorAt("nameForm")}
                                    />
                                    <NewInput
                                        value={phone}
                                        onChange={onChangePhone}
                                        placeholder="Telefonnummer"
                                        validation="phone"
                                        onError={() => addFormError("phone")}
                                        onErrorSolved={() =>
                                            clearError("phone")
                                        }
                                        forceError={hasErrorAt("phone")}
                                    />
                                    <NewInput
                                        value={address}
                                        onChange={onChangeAddress}
                                        placeholder="Anschrift"
                                        validation="address"
                                        onError={() => addFormError("address")}
                                        onErrorSolved={() =>
                                            clearError("address")
                                        }
                                        forceError={hasErrorAt("address")}
                                    />
                                    <NewInput
                                        value={email}
                                        onChange={onChangeEmail}
                                        placeholder="E-Mail"
                                        validation="email"
                                        onError={() => addFormError("email")}
                                        onErrorSolved={() =>
                                            clearError("email")
                                        }
                                        forceError={hasErrorAt("email")}
                                    />
                                </Columns.Column>
                                <Columns.Column>
                                    <NewInput
                                        value={customerNumber}
                                        onChange={onChangeCustomerNumber}
                                        placeholder="Kundennummer"
                                        validation="lPGCustomerNumber"
                                        onError={() =>
                                            addFormError("lPGCustomerNumber")
                                        }
                                        onErrorSolved={() =>
                                            clearError("lPGCustomerNumber")
                                        }
                                        forceError={hasErrorAt(
                                            "lPGCustomerNumber"
                                        )}
                                    />
                                    <NewInput
                                        value={counterNumber}
                                        onChange={onChangeCounterNumber}
                                        placeholder="Zählernummer"
                                        validation="counter"
                                        onError={() =>
                                            addFormError("counterNumber")
                                        }
                                        onErrorSolved={() =>
                                            clearError("counterNumber")
                                        }
                                        forceError={hasErrorAt("counterNumber")}
                                    />
                                    <NewInput
                                        value={counterLevel}
                                        onChange={onChangeCounterLevel}
                                        placeholder="Zählerstand"
                                        validation="counterLevel"
                                        onError={() =>
                                            addFormError("counterLevel")
                                        }
                                        onErrorSolved={() =>
                                            clearError("counterLevel")
                                        }
                                        forceError={hasErrorAt("counterLevel")}
                                    />
                                    <DatePicker
                                        label="Ablesedatum"
                                        maxDate={new Date()}
                                        onChange={onDateChanged}
                                        value={date}
                                    />
                                    {hasErrorAt("date") && (
                                        <FormError message="Bitte gib ein Ablesedatum an." />
                                    )}
                                </Columns.Column>
                            </Columns>

                            <Columns>
                                <Columns.Column>
                                    <NewCheckbox
                                        checked={isPrivacyPolicyAccepted}
                                        name="isPrivacyPolicyAccepted"
                                        required
                                        onChange={onChangeIsPrivacyAccepted}
                                        validation="isPrivacyPolicyAccepted"
                                        onError={() =>
                                            addFormError(
                                                "isPrivacyPolicyAccepted"
                                            )
                                        }
                                        onErrorSolved={() =>
                                            clearError(
                                                "isPrivacyPolicyAccepted"
                                            )
                                        }
                                        forceError={hasErrorAt(
                                            "isPrivacyPolicyAccepted"
                                        )}
                                    >
                                        Ich akzeptiere die
                                        <a href="/datenschutz" target="_blank">
                                            {" "}
                                            Datenschutzerklärung
                                        </a>
                                    </NewCheckbox>
                                </Columns.Column>
                                <Columns.Column>
                                    <Button
                                        fullWidth
                                        data-gtag={`${name}:contact-form`}
                                        type="submit"
                                    >
                                        SENDEN
                                    </Button>
                                </Columns.Column>
                            </Columns>
                        </form>
                    </>
                )}
            </Margin>
        </>
    );
};

export default FluessiggasContact;
